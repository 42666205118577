
import {Vue, Component} from 'vue-property-decorator';
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import {ITask} from "@/plugins/uyap-plugin/store/modules/GorevYoneticisiTypes";

@Component({
  components: {DeleteDialog}
})
export default class UyapTakipHashList extends Vue {
  onFly = false;
  deleting = false;
  isDeleteModeActive = false;
  items = [];
  selectedItems = [];
  // TODO: detaylandırma gerekli
  headers = [
    {text: "Birim Adı", value: "birim_adi"},
    {text: "Esas No", value: "esas_no"},
    {text: "Hash", value: "hash"},
    {text: "Durum", value: "status"},
    {text: "İşlemler", value: "actions", width: 50, align: 'center', sortable: false},
  ];

  mounted() {
    this.load();
  }

  async load() {
    try {
      this.onFly = true;
      let res = await this.$http.get('/api/v1/uyap-takip');
      this.items = res.data;
    } finally {
      this.onFly = false;
    }
  }

  async onDeleteMultiple() {
    try {
      this.deleting = true;
      this.isDeleteModeActive = false;
      await this.$http.post('/api/v1/uyap-takip-sil', {ids: this.selectedItems.map((item:any) => item.id)});
      this.selectedItems = [];
    } catch (e) {
      this.$toast.error('Silme işleminde hata oluştu. ' + e.message);
    } finally {
      await this.load();
      this.deleting = false;
    }
  }
}
